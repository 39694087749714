import React, { Component } from "react";
import { Navbar, Container, Nav, Button, Image } from "react-bootstrap";
import { Link, animateScroll as scroll } from "react-scroll";
import "./styles.css";
import needle from "../../assets/images/needle.png";
import needle2 from "../../assets/images/needle_tag_logo.png";
import needle3 from "../../assets/images/needle.png";


export default class Navigate extends Component {

  scrollToTop = () => {
    scroll.scrollToTop();
  };
  render() {
     return (
      <React.Fragment>


        <Navbar className=" navbar navbar-light" fixed="top" collapseOnSelect
          expand="md">
          <Container fluid>
            <Navbar.Brand className="needle_logo">
              <div className="logo_div">
                <Image fluid classname="needle-logo" src={needle3} onClick={this.scrollToTop} />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav"
              className="justify-content-end">


              <Nav>

                <Nav.Item><Nav.Link className="navLink" href="#homeSection" >Home</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="navLink" href="#aboutSection" >About Us</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="navLink" href="#clientsSection" >Clients</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="navLink" href="#servicesSection" >Services</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="navLink" href="#projectsSection" >Projects</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="navLink" href="#teamsSection" >Team</Nav.Link></Nav.Item>

                <Nav.Item>
                 <Nav.Link className="navLink" href="#contactSection">
                 <div className="homeContact navContactButton" variant="default">
                    <Button variant="danger"  style={{ color: "black", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }} outline>

                      <h4 className="h3Contact">Contact</h4>
                    </Button> </div>
                 </Nav.Link>
                   

                </Nav.Item>
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>



      </React.Fragment>
    );
  }
}
