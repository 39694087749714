import React, { useState } from 'react';
import { Container, Row, Col, Image, Card, Accordion } from 'react-bootstrap';
import { Link, animateScroll as scroll } from "react-scroll";
import Button from 'react-bootstrap/Button';
import about2 from "../../assets/images/about-us-bg.png";
import about from "../../assets/images/about-bg.png";
import './styles.css';
const About = () => {
    const [moreOrLess, setMoreOrLess] = useState(true);
    const changeMoreOrLess = () => {
        moreOrLess ? setMoreOrLess(false) : setMoreOrLess(true);
    }

    return (
        <React.Fragment>         
            <div className="about-body" id="aboutSection">
                <Container className="toJustify" fluid>
                    <Row>
                        <Col md={6}>
                            <Image src={about} className="image-fluid ab_im" />
                        </Col>
                        <Col className="ab_card_cont" >
                            <div className="about-sec1">
                                <div className="about_circle"> </div>
                                <Card className="aCard">
                                    <Card.Body>
                                        <h2 className="text-danger fw-bold section_head display-5 ab_head">About Us</h2>
                                        <Card.Text className="cardText"> Needle Technology is into providing software development and IT consulting services. We are a team of performance-driven experts that uses technology as a tool to simplify operational processes for our clients and provide them with tailored services. Our goal is to offer qualitative value-added services geared towards providing efficiency and continuous improvement. </Card.Text>
                                        <Accordion  flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header onClick={() => changeMoreOrLess()}>
                                                    {moreOrLess ? <p className="smol">Show More...</p> : <p  className="smol">Show Less...</p>}
                                                </Accordion.Header>
                                                <Accordion.Body className="accBody">
                                                    <Card.Text className="cardText">Our core expertise include; Business Analysis, Monitoring and Evaluation, Data Analysis, Business Intelligence and Interactive dashboards, Mobile Applications, costing vs. budgeting of delivering IT project services, amongst others. Our clientele covers Government MDA’s, Multinational Corporations, Banks, Educational Bodies, Small & Medium Enterprises, and Private Individuals.</Card.Text>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}

export default About;



