import React, { Component } from "react";
import Slider from "react-slick";
import Image from "react-bootstrap/Image";
import ABDC from "../../assets/images/logos/ABDC.jpg";
import Anadariya from "../../assets/images/logos/Anadariya.png";
import blueCamel from "../../assets/images/logos/blue-camel.png";
import CBN from "../../assets/images/logos/CBN.jpg";
import Constrix from "../../assets/images/logos/Constrix-logo.jpg";
import Dangote from "../../assets/images/logos/Dangote-logo.png";
import Datech from "../../assets/images/logos/Datech.png";
import earthpoint from "../../assets/images/logos/earthpoint.png";
import errandBoy from "../../assets/images/logos/Errandboy.jpg";
import gourmetPizza from "../../assets/images/logos/Gourmet.png";
import Humanitarian from "../../assets/images/logos/Humanitarian.jpg";
import INEC from "../../assets/images/logos/INEC.png";
import kadunaState from "../../assets/images/logos/kaduna-state.jpg";
import keystoneBank from "../../assets/images/logos/Keystone-Bank.png";
import Nirsal from "../../assets/images/logos/NIRSAL.jpg";
import PCNI from "../../assets/images/logos/PCNI.jpg";
import pinkFleur from "../../assets/images/logos/pink-fleur.png";
import royalBliss from "../../assets/images/logos/RoyalBliss.jpg";
import unityBank from "../../assets/images/logos/Unity-Bank.png";
import faan from "../../assets/images/logos/FAAN.jpg";
import './style.css';
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "lightslategrey" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "lightslategrey" }}
      onClick={onClick}
    />
  );
}

export default class Clients extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 6,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      arrows: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      adaptiveHeight: true,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    };
    return (
      <>
        <div className="clients_section2" id="clientsSection">
          <center><h2 className="text-danger fw-bold section_head py-3 display-5">Our Clients</h2></center>
          <Slider {...settings} className="sliderCont">
            <div className="logoDiv"><Image alt="logo" src={CBN} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={unityBank} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={keystoneBank} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={Dangote} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={kadunaState} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={ABDC} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={Humanitarian} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={Anadariya} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={blueCamel} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={Constrix} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={Datech} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={earthpoint} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={errandBoy} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={gourmetPizza} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={INEC} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={Nirsal} width={200} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={PCNI} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={pinkFleur} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={royalBliss} width={150} height={150} rounded /></div>
            <div className="logoDiv"><Image alt="logo" src={faan} width={150} height={150} rounded /></div>
                                                                                                                                                   
          </Slider>
        </div>
      </>
    );
  }
}
