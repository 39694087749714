import React, {useState, useEffect} from "react";
import { Row, Col, Container } from "react-bootstrap";

const Contact = () => {

  const [currentYear, setCurrentYear] = useState();

  const getYear = () => {
    const d = new Date();
    let year = d.getFullYear();
    setCurrentYear(year);
  }

  useEffect(() => {
    getYear();
  });

  return (
    <React.Fragment>
     <div id="contactSection">
        <div className="map">
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.740667804139!2d7.469159414276841!3d9.087372890575345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0ba9c728f379%3A0x5b91b590d55fb00e!2sNeedle%20Technology%20Ltd!5e0!3m2!1sen!2sng!4v1636038740890!5m2!1sen!2sng"
              width="100% "
              height="500px"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>


       <div className="contacts">
          <h2 className="text-white text-center section_head mb-5 display-5">Contacts</h2>
          <hr className="underline" />
<Container fluid>
          <Row>
            <Col  lg={3} className="con-col lower_height" >
              <p className="fw-bold">Give us a call</p>

              <div className="reach">
                <a href="tel:09011529918" className="text-white reach ph_number">
                09011529918
                </a>
              </div>
            </Col>
            <Col  lg={3} className="con-col lower_height" >
              <p className="fw-bold">Send us an email</p>
              <div className="reach text-white">
                <a
                  href="mailto:needlesupport@needletech.ng"
                  className="text-white reach ph_number"
                >
                  needlesupport@needletech.ng
                </a>
              </div>
            </Col>
            <Col  lg={3} className="con-col " >
              <p className="fw-bold">Visit our office</p>
              <p>No. 14 Buchanan Crescent, Wuse II, Abuja. </p>
            </Col>
            <Col  lg={3} className="con-col">
              <p className="fw-bold">Get in touch</p>
                  <div className=" text-center">
               
                <a
                  href="https://web.facebook.com/needletechnology.ng/"
                  target="blank"
                  className="bg-white p-2 text-dark m-2  rounded-circle icon"
                >
                 
                    <i className="fa fa-facebook"></i>
                 
                </a>
                <a href="https://twitter.com/@NeedleNigeria" target="blank" className="bg-white p-2 text-black m-2 rounded-circle icon">
                 
                    <i className="fa fa-twitter"></i>
                 
                </a>
               
              </div>
            </Col>
          </Row>
          </Container>

          <hr className="underline" />
          <p className="nt">{currentYear} Needle Technology. All rights reserved.</p>
        </div>
      </div>


    </React.Fragment>
  );
};

export default Contact;
