import React from "react";
import Caption from "./caption.js";
import Carousel from "react-bootstrap/Carousel";
import "./style.css";
import home1 from "../../assets/images/Site pictures/Site pictures/slide1.jpg";
import home2 from "../../assets/images/Site pictures/Site pictures/slide3.jpg";
import home3 from "../../assets/images/Site pictures/Site pictures/slide4.jpg";
import home4 from "../../assets/images/Site pictures/Site pictures/slide6.jpg";

const home = () => {
  return (
    <React.Fragment>
      <div id="homeSection">
      <Carousel variant="dark" pause={false} fade indicators={true}>
          <Carousel.Item>
            <img className="anImg" src={home1} />
            <Carousel.Caption>
              <Caption />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="anImg" src={home2} />
            <Carousel.Caption>
              <Caption />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="anImg" src={home3} />
            <Carousel.Caption>
              <Caption />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="anImg" src={home4} />
            <Carousel.Caption>
              <Caption />
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <div className="homeSection" id="homeSection"> */}
       
      {/* </div> */}
{/* 
<div id="section1" class="container-fluid">
  <h1>Section 1</h1>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
</div>
<div id="section2" class="container-fluid">
  <h1>Section 2</h1>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
</div>
<div id="section3" class="container-fluid">
  <h1>Section 3</h1>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
</div>
<div id="section41" class="container-fluid">
  <h1>Section 4 Submenu 1</h1>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
</div>
<div id="section42" class="container-fluid">
  <h1>Section 4 Submenu 2</h1>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
  <p>Try to scroll this section and look at the navigation bar while scrolling! Try to scroll this section and look at the navigation bar while scrolling!</p>
</div> */}

    </React.Fragment>
  );
};

export default home;
