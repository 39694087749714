import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import MandE from "../../components/assets/img/icons/MandE.png";
import agri from "../../components/assets/img/icons/agri.png";
import saas from "../../components/assets/img/icons/saas.png";
import consul from "../../components/assets/img/icons/consul.png";
import training from "../../components/assets/img/icons/training.png";
import education from "../../components/assets/img/icons/education.png";
import Monitoring from "../../components/assets/img/services/Monitoring.jpg";
import Agritech from "../../components/assets/img/services/Agritech.jpg";
import Software from "../../components/assets/img/services/Software.jpg";
import Consultancy from "../../components/assets/img/services/Consultancy.jpg";
import Training from "../../components/assets/img/services/Training.jpg";
import Education from "../../components/assets/img/services/Education.jpg";

import "./styles.css";
const Services = () => {
  return (
    <React.Fragment>
      <Container id="servicesSection">
        <div className="  text-center pt-5 p-5">
          <h2 className="text-danger fw-bold section_head display-5">Services</h2>
        </div>

        <Row className="mb-5 service-sec">
          <Col className="spaceBottom" sm={12} md={6} lg={4} m-3>
            <div className="indivCard1">
              <Card className="pro-img">
                <Card.Img
                  src={Monitoring}
                  alt="Card image"
                  className="service-card op"
                />
                <div className="indivCont1">
                  <Card.ImgOverlay>
                    <div>
                      <img
                        src={MandE}
                        alt="Card"
                        height="90px"
                        width="90px"
                        className="card-icon my-3 "
                      />
                    </div>
                    <Card.Title className="fw-bold ">
                      Monitoring and Evaluation
                    </Card.Title>
                    <Card.Text className="service-text justify my-3">
                      Our M&E processes involve using technology to monitor
                      project status and providing stakeholders across various
                      sectors with detailed information on/and progress reports
                      towards achieving set and agreed targets by systematically
                      gathering and analyzing data and information.
                    </Card.Text>
                  </Card.ImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
            <div className="indivCard1">
              <Card className="pro-img">
                <Card.Img
                  src={Agritech}
                  alt="Card image"
                  className="service-card op"
                />
                <div className="indivCont1">
                  <Card.ImgOverlay className="overlaidContent">
                    <div>
                      <img
                        src={agri}
                        alt="Card"
                        height="90px"
                        width="90px"
                        className="card-icon mt-3"
                      />
                    </div>
                    <Card.Title className="fw-bold mt-3">AgricTech</Card.Title>
                    <Card.Text className="service-text justify my-3">
                      With our team of experts, we offer professional services
                      for farmers and other stakeholders by applying our
                      innovative technology integrated with GIS software and
                      data analytics to improve the concept of farm business
                      throughout the production cycle by making it more
                      efficient, safer, and profitable.
                    </Card.Text>
                  </Card.ImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
            <div className="indivCard1">
              <Card className="pro-img">
                <Card.Img
                  src={Software}
                  alt="Card image"
                  className="service-card op"
                />
                <div className="indivCont1">
                  <Card.ImgOverlay className="overlaidContent">
                    <div>
                      <img
                        src={saas}
                        alt="Card"
                        height="90px"
                        width="90px"
                        className="card-icon mt-3"
                      />
                    </div>
                    <Card.Title className="fw-bold mt-3">
                      Software as a Service
                    </Card.Title>
                    <Card.Text className="service-text justify mt-3">
                      As a rapidly growing tailored SAAS provider, we provide
                      services spanning from e-Commerce, Audit Management,
                      Logistics, and HR among others. We specialize in process
                      optimization and tailored software develeopment, which
                      enables our clients to improve their business operations
                      seamlessly.
                    </Card.Text>
                  </Card.ImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
            <div className="indivCard1">
              <Card className="pro-img">
                <Card.Img
                  src={Consultancy}
                  alt="Card image"
                  className="service-card op"
                />
                <div className="indivCont1">
                  <Card.ImgOverlay className="overlaidContent">
                    <div>
                      <img
                        src={consul}
                        alt="Card"
                        height="90px"
                        width="90px"
                        className="card-icon mt-3"
                      />
                    </div>
                    <Card.Title className="fw-bold mt-3">
                      Consultancy
                    </Card.Title>
                    <Card.Text className="service-text justify mt-3">
                      Our consultancy services are always targeted towards
                      unbundling the operations of our clients's business needs
                      by identifying and resolving bottlenecks but most
                      importantly creating growth opportunities. We provide
                      consultancy services on Business Process clean-up,
                      Business Process Review, Automation Systems,IT Policy, and
                      Governance.
                    </Card.Text>
                  </Card.ImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="spaceBottom" sm={12} md={6} lg={4}>
            <div className="indivCard1">
              <Card className="pro-img">
                <Card.Img
                  src={Training}
                  alt="Card image"
                  className="service-card op"
                />
                <div className="indivCont1">
                  <Card.ImgOverlay className="overlaidContent">
                    <div>
                      <img
                        src={training}
                        alt="Card"
                        height="90px"
                        width="90px"
                        className="card-icon mt-3"
                      />
                    </div>
                    <Card.Title className="fw-bold mt-3">Training</Card.Title>
                    <Card.Text className="service-text justify mt-3">
                      Needle Training Programs offer hands-on training using
                      exciting coaching methodologies. The programs ensure our
                      clients and their employees have the knowledge, skills,
                      and support to perform their jobs effectively whilst
                      staying ahead in bridging skill gaps on technological
                      advancements and process changes.
                    </Card.Text>
                  </Card.ImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
            <div className="indivCard1">
              <Card className="pro-img">
                <Card.Img
                  src={Education}
                  alt="Card image"
                  className="service-card op"
                />
                <div className="indivCont1">
                  <Card.ImgOverlay className="overlaidContent">
                    <div>
                      <img
                        src={education}
                        alt="Card"
                        height="90px"
                        width="90px"
                        className="card-icon mt-3"
                      />
                    </div>
                    <Card.Title className="fw-bold mt-3 ">
                      Educational Intelligence Suite
                    </Card.Title>
                    <Card.Text className="service-text justify mt-3">
                      We provide educational institutions with software
                      solutions that offer vast features tailored to enhance
                      academic management and provide an easy flow of
                      administrative processes that administrators can leverage
                      to facilitate communication, provide centralized
                      information systems and be more efficient and effective.
                    </Card.Text>
                  </Card.ImgOverlay>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>

      
    </React.Fragment>
  );
};

export default Services;
