import React from 'react';
import {  CardImg, Container, Row, Col } from "react-bootstrap";
import abdc from "../../components/assets/img/projects/abdc.jpg";
import cbn from "../../components/assets/img/projects/cbn.jpg";
import dangote from "../../components/assets/img/projects/dangote.jpg";
import faan from "../../components/assets/img/projects/faan.jpg";
import humanitarian from "../../components/assets/img/projects/humanitarian.jpg";
import kaduna from "../../components/assets/img/projects/kaduna.jpg";

const projectsss = () => {
  return (
      <React.Fragment>
 <Container fluid id="projectsSection" className="projects p-3">
        <div className="text-center mt-4 p-5">
          <h2 className="text-danger fw-bold section_head">Projects</h2>
        </div>
        <Container >
        <Row>

        <Col md={6} >
<CardImg
src={cbn}
alt="Card image"
/>
</Col>

<Col md={6} >
<div className='px-4 py-3 pro-text'>
<h3 className="mt-5 fw-bold">
Anchor Borrower's Programme
</h3>
<p className="mt-3 project-textss project-text justify">
        With our expertise in Technonoly 
        and field operations, we are 
        working as mappers and
        Monitoring and Evaluating the 
        Anchor Borrower's Programme.
        The Programme was established by 
         the Central Bank of Nigeria in 2015 to create
        an econimic link between farmers and processors 
        for increase in agricultural output.
</p>
</div>
</Col>
</Row>

<Row className='mt-4'>

        <Col md={6} >
        <div className='px-4 py-3 pro-text'>
        <h3 className="mt-5 fw-bold">
Audit Management Tool
</h3>
<p className="project-textss project-text justify">
This is a solution developed to encompass
    business processes for Abdulkadeer & Co.
     The solution provides an interface for 
    Human Resource Management, Audit Jobs Tracking,
    Financial Management, and also gives the
    management an overview of all activities of
    employees and business in general.
</p>
        </div>
</Col>
<Col md={6}>
<CardImg
src={abdc}
alt="Card image"
/>
</Col>


</Row>


<Row className='mt-4'>

<Col md={6}>
<CardImg
src={faan}
alt="Card image"
/>
</Col>

<Col md={6}>
<div className='px-4 py-3 pro-text'>
<h3 className="mt-5 fw-bold">
FAAN Airport Protocol Service
</h3>
<p className="project-textss project-text justify">
As COVID-19 precautionary measure to limit the number 
of non-travellers in the airport terminals, this
application was designed to enable travellers request
and pre-book different services available and
access their reservation history, wallet-funding history, and
much more from their mobile 
devices.
</p>
</div>
</Col>

</Row>

<Row className='mt-4'>
<Col md={6}>
<div className='px-4 py-3 pro-text'>
<h3 className="mt-5 fw-bold">
Dangote e-Commerce
</h3>
<p className="project-textss project-text justify">
In order to help
    the Dangote Cement Company eliminate
    manual processes of capturing customers' ordering details and
    activate online ordering processes this application was designed
    to also gives the
    administration an overview of the processes
    through the management portal.
</p>
</div>
</Col>

<Col md={6}>
<CardImg
src={dangote}
alt="Card image"
/>
</Col>



</Row>

<Row className='mt-4'>

<Col md={6}>
<CardImg
src={kaduna}
alt="Card image"
/>
</Col>

<Col md={6}>

<div className='px-4 py-3 pro-text'>
<h3 className="mt-5 fw-bold">
Expenditure Tracking System
</h3>
<p className="project-textss project-text justify">
To track both infrastructure and program
    type budget including monthly expenditure
    plans this solution was designed for Kaduna State Ministry of
    Budget and Planning. It also serves
    as a management tool that provides pictoral storylines
    on a month-by-month basis and budget implementation.

</p>
</div>
</Col>

</Row>

<Row className='mt-4'>
<Col md={6} >

<div className='px-4 py-3 pro-text'>
<h3 className="mt-5 fw-bold">
The Nigerian Poverty Map
</h3>
<p className="project-textss project-text justify">
The poverty map was built under the Ministry of Humanitarian Affairs and Disaster management.
It incorporates the Nigeria Poverty Clock
  with interactive Dashboards providing insight into the poverty level of the
  country using the Nigerian
  Living Standard Survey by the National 
  Bureau of Statistics.


</p>
</div>
</Col>
<Col md={6}>
<CardImg
src={humanitarian}
alt="Card image"
/>
</Col>



</Row>
        </Container>
        </Container>
      </React.Fragment>
  )
}

export default projectsss