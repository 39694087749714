import React from 'react';

const caption = () => {
    return (
        <React.Fragment>
            <div className="cont2">
                <h1 className="display-3 text1">Tailored technology <br /> to meet your needs</h1>
                <h3><em className="text2">...turn your ideas into results.</em></h3>
            </div>
        </React.Fragment>
    );
};

export default caption;

