import React from "react";
import { Card, CardImg, Container, Row, Col } from "react-bootstrap";
import abdc from "../../components/assets/img/projects/abdc.jpg";
import cbn from "../../components/assets/img/projects/cbn.jpg";
import dangote from "../../components/assets/img/projects/dangote.jpg";
import faan from "../../components/assets/img/projects/faan.jpg";
import humanitarian from "../../components/assets/img/projects/humanitarian.jpg";
import kaduna from "../../components/assets/img/projects/kaduna.jpg";

const Projects = () => {
  return (
    <React.Fragment>
      <Container fluid id="projectsSection" className="projects p-3">
        <div className="text-center mt-4 p-5">
          <h2 className="text-danger fw-bold section_head display-5">Projects</h2>
        </div>
          <Row>
          <Col className="spaceBottom"  sm={12} md={6} lg={4}>

          <Card className="m-3   border-d">
            <CardImg src={cbn} alt="Card image" />
            <div className=" pro-text pro-img">
             <div className="pro-title p-3 ">
               <Card.Title className="fw-bold">
                Anchor Borrower's Programme
              </Card.Title>
              </div> 
              <div>
              <Card.Text className="project-text p-3 justify">
                With our expertise in Technology and field operations, we are
                working as mappers and Monitoring & Evaluating the Anchor
                Borrower's Programme. The Programme was established by the
                Central Bank of Nigeria in 2015 to create an economic link
                between farmers and processors to increase agricultural
                output.
              </Card.Text>
              </div>
            </div>
          </Card>
          </Col>

          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
          <Card className="m-3 border-d ">
            <CardImg src={abdc} alt="Card image" />
            <div className=" pro-text pro-img">
             <div className="pro-title p-3 ">
              <Card.Title className=" fw-bold">
                Audit Management Tool
              </Card.Title>
              </div>
              <div>
              <Card.Text className="project-text p-3 justify">
                This is a solution developed to encompass business processes for
                Abdulkadeer & Co. The solution provides an interface for Human
                Resource Management, Audit Jobs Tracking, Financial Management,
                and also gives the management an overview of all activities of
                employees and business in general.
              </Card.Text>
              </div>
            </div>
          </Card>
          </Col>

          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
          <Card className="m-3 border-d ">
            <CardImg src={faan} alt="Card image" />
            <div className=" pro-text pro-img">
            <div className="pro-title p-3 ">
              <Card.Title className="fw-bold">
                FAAN Airport Protocol Service
              </Card.Title>
              </div>
              <Card.Text className="project-text p-3 justify">
                As COVID-19 precautionary measure to limit the number of
                non-travellers in the airport terminals, the Airport Protocol Service application was
                designed to enable travellers request and pre-book different
                services available and access their reservation and
                wallet-funding history, and much more from their mobile devices.
              </Card.Text>
            </div>
          </Card>
          </Col>

          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
          <Card className="m-3 border-d ">
            <CardImg src={dangote} alt="Card image" />
            <div className=" pro-text pro-img">
            <div className="pro-title p-3 ">
              <Card.Title className="fw-bold">
                Dangote e-Commerce
              </Card.Title>
              </div>
              <div>
              <Card.Text className="project-text p-3 justify">
                In order to help the Dangote Cement Company eliminate manual
                processes of capturing customers' ordering details and activate
                online ordering processes this application was designed to also
                gives the administration an overview of the processes through
                the management portal.
              </Card.Text>
              </div>
            </div>
          </Card>
          </Col>

          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
          <Card className="m-3 border-d ">
            <CardImg src={kaduna} alt="Card image" />
            <div className=" pro-text pro-img">
            <div className="pro-title p-3 ">
              <Card.Title className="fw-bold">
                Expenditure Tracking System
              </Card.Title>
              </div>
              <div>
              <Card.Text className="project-text p-3 justify">
                To track both infrastructure and program type budget including
                monthly expenditure plans this solution was designed for Kaduna
                State Ministry of Budget and Planning. It also serves as a
                management tool that provides pictoral storylines on a
                month-by-month basis and budget implementation.
              </Card.Text>
              </div>
            </div>
          </Card>
          </Col>

          <Col className="spaceBottom"  sm={12} md={6} lg={4}>
          <Card className="m-3 border-d ">
            <CardImg src={humanitarian} alt="Card image" />
            <div className=" pro-text pro-img">
            <div className="pro-title p-3 ">
              <Card.Title className="fw-bold">
                The Nigerian Poverty Map
              </Card.Title>
              </div>
              <div>
              <Card.Text className="project-text p-3 justify">
                The poverty map was built under the Ministry of Humanitarian
                Affairs and Disaster management. It incorporates the Nigeria
                Poverty Clock with interactive Dashboards providing insight into
                the poverty level of the country using the Nigerian Living
                Standard Survey by the National Bureau of Statistics.
              </Card.Text>
              </div>
            </div>
          </Card>
          </Col>
          </Row>
      </Container>
    </React.Fragment>
  );
};

export default Projects;
