import react from "react";
import "./App.css";
import About from "./components/About";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navigate from "./components/Navigate";
import Projects from "./components/Projects";
import Projectss from "./components/Projects/projectss";
import Projectsss from "./components/Projects/Projectsss";
import Services from "./components/Services";
import Teams from "./components/Teams";

function App() {
  return (
    <div className="App" >
       <Navigate />
     <Home />
      <About />
      <Clients /> 
      <Services />
      <Projects />
      <Teams />
      <Contact />
    </div>
  );
}

export default App;
